import React from 'react'

import jorgealberto from '../../assets/cv/jorgealbertoestradacuevas.jpeg'


function JorgeAlberto() {
    return (
        <div className="container py-5">
              <img src={jorgealberto} class="img-fluid" alt="..." />
        </div>
    )
}

export default JorgeAlberto
