import React from 'react'

import fredycesar from '../../assets/cv/fredycesararenasvaldez.jpeg'


function FredyCesar() {
    return (
        <div className="container py-5">
              <img src={fredycesar} class="img-fluid" alt="..." />
        </div>
    )
}

export default FredyCesar
