import React from 'react'

import enriquepineda from '../../assets/cv/enriquepinedafigueroa.jpeg'


function EnriquePineda() {
    return (
        <div className="container py-5">
              <img src={enriquepineda} class="img-fluid" alt="..." />
        </div>
    )
}

export default EnriquePineda
