import React from 'react'


import gerardocasamata from '../../assets/cv/gerardocasamatagomez.jpeg'


function GerardoCasamata() {
    return (
        <div className="container py-5">
              <img src={gerardocasamata} class="img-fluid" alt="..." />
        </div>
    )
}

export default GerardoCasamata
