import React from 'react'


import jorgeluna from '../../assets/cv/jorgemichelluna.jpeg'


function JorgeLuna() {
    return (
        <div className="container py-5">
              <img src={jorgeluna} class="img-fluid" alt="..." />
        </div>
    )
}

export default JorgeLuna
