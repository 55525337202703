import React from 'react'

import arturovazquez from '../../assets/cv/arturovazquezespinosa.jpeg'


function ArturoVazquez() {
    return (
        <div className="container py-5">
              <img src={arturovazquez} class="img-fluid" alt="..." />
        </div>
    )
}

export default ArturoVazquez
