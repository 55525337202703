import React from 'react'


import antoniomejia from '../../assets/cv/antoniomejiaceja.jpeg'
import enriquepineda from '../../assets/cv/enriquepinedafigueroa.jpeg'


function AntonioMejia() {
    return (
        <div className="container py-5">
              <img src={antoniomejia} class="img-fluid" alt="..." />
              <img src={enriquepineda} class="img-fluid" alt="..." />
        </div>
    )
}

export default AntonioMejia
