import React from 'react'


import gabrielaocampo from '../../assets/cv/gabrielaocampogarcia.jpeg'


function GabrielaOcampo() {
    return (
        <div className="container py-5">
              <img src={gabrielaocampo} class="img-fluid" alt="..." />
        </div>
    )
}

export default GabrielaOcampo
